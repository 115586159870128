import { MembershipLevels } from '@aaa/emember/store-types';

export const getCampanaTowingMiles = (membershipLevel: MembershipLevels) => {
  switch (membershipLevel) {
    case 'Basic':
      return 3;
    case 'Plus':
      return 100;
    case 'Plus-RV':
      return 100;
    case 'Premier':
      return 200;
    default:
      return 0;
  }
};

export const getCampanaMembershipType = (membershipLevel: MembershipLevels) => {
  switch (membershipLevel) {
    case 'Basic':
      return 'B';
    case 'Plus':
      return 'P';
    case 'Plus-RV':
      return 'R';
    case 'Premier':
      return 'E';
    default:
      return '';
  }
};
