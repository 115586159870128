import { Component, Input } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'ava-file-manager-preview',
  template: `
    @if (file) {
      <div style="display: flex; flex-wrap: wrap; align-items: center">
        <img
          [src]="file.url"
          [alt]="file?.name"
          style="max-width: 250px; padding-right: 20px; width: 100%; height: auto"
        />
        <div>
          {{ file.url }}
        </div>
      </div>
    }
  `,
})
export class FileManagerPreviewComponent {
  @Input() file: NzUploadFile | undefined;
}
