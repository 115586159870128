import { Component, Input, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { FileManagerService } from '../file-manager.service';
import FirebaseStorageRef = firebase.storage.Reference;
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'ava-file-manager-list',
  template: `
    @if (fileList.length) {
      <ava-file-manager-upload [directory]="directory" [fileList]="fileList" />
    }
  `,
})
export class FileManagerListComponent implements OnInit {
  @Input() directory = '';
  defaultFileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ];
  fileList: NzUploadFile[] = [];

  constructor(
    private fmService: FileManagerService,
    private afStorage: AngularFireStorage,
  ) {}

  ngOnInit(): void {
    this.getFileList();
  }

  private getFileList() {
    if (this.directory) {
      const storageRef: FirebaseStorageRef = firebase.storage().ref();
      const listRef = storageRef.child(this.directory);
      listRef
        .listAll()
        .then((result) => {
          result.items.forEach((item) => {
            this.getWindowMetaData(item, storageRef);
            const file: NzUploadFile = {
              uid: '',
              name: item.name, // this.fmService.domain + "/" + this.directory + "/" + item.name,
              url: this.fmService.domain + '/' + item.fullPath,
              thumbUrl: this.fmService.domain + '/' + item.fullPath,
              status: 'done',
            };
            this.fileList.push(file);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  getWindowMetaData(item: FirebaseStorageRef, storageRef: FirebaseStorageRef): void {
    const fileRef = storageRef.child(item.fullPath);
    fileRef
      .getMetadata()
      .then((metadata) => {
        this.fileList.forEach((file) => {
          if (file.name === item.name) {
            file.metadata = metadata;
            if (file.message === 'Upload error') {
              file.message = '';
            }
          }
        });
        // console.log(metadata)
        // Metadata now contains the metadata for 'images/forest.jpg'
      })
      .catch(() => {
        // Uh-oh, an error occurred!
      });
  }
}
