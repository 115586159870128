import { Component, Inject, Input } from '@angular/core';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';
import { RxState } from '@rx-angular/state';

@Component({
  selector: 'ava-file-manager',
  template: `
    @if (globalState$ | async; as g) {
      @if (g.afAuthIdTokenResult && directory) {
        <ava-file-manager-list [directory]="directory" />
      }
    }
  `,
})
export class FileManagerComponent {
  @Input() directory = '';
  globalState$ = this.globalState.select();

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {}
}
