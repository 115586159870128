import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest, shareReplay, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import {
  OfficeAgent,
  AgentOrderMode,
  OfficeAgents,
  OfficeAgentsService,
  ParamsObj,
  AgentTypeOfficeAgents,
} from './office-agents.service';
import { AgentService } from '../../services/agent';
import { AgentTypeService } from '../../services/agent-type';

@Component({
  selector: 'ava-office-agents',
  template: `
    @if (officeId) {
      @for (data of officeAgentsService.templateData[id]; track data; let last = $last) {
        <div style="padding-top: 30px">
          <div style="font-size: 36px; font-weight: 400; text-align: left">
            {{ data.agentType.officeHeading }}
          </div>
          <div style="display: flex; flex-wrap: wrap">
            @for (agent of data.agents; track agent) {
              <nz-card
                nzBorderless
                style="width: 300px; margin: 30px 30px 30px 0"
                [nzBodyStyle]="{ display: 'grid', 'grid-gap': '10px', padding: '20px' }"
              >
                <a class="agent" style="text-decoration: none" [href]="agent.pathName">
                  <div
                    class="photo"
                    [style]="'background-image: url(' + agent.photos[0].derivatives.searchResult + ')'"
                  ></div>
                  <div class="name">{{ agent.firstNames[0] }} {{ agent.lastNames[0] }}</div>
                  <svg class="icon" width="40" height="40" viewBox="0 0 1024 1024">
                    <title>external link</title>
                    <path d="M489.6 579.2l249.6-249.6v124.8h64v-227.2h-240v64h121.6l-243.2 243.2z"></path>
                    <path
                      d="M969.6 60.8h-752v121.6h-134.4v761.6h716.8v-134.4h169.6v-748.8zM768 915.2h-652.8v-697.6h105.6v598.4h547.2v99.2zM905.6 748.8h-624v-624h624v624z"
                    ></path>
                  </svg>
                </a>

                @if (agent.appointmentLinks[0]?.url; as appointmentUrl) {
                  <button nz-button nzType="primary">
                    <a [href]="appointmentUrl">Book Appointment</a>
                  </button>
                }

                @if (agent.travelLinks[0]?.url; as travelLink) {
                  <button nz-button nzType="primary">
                    <a [href]="travelLink">Get Travel Quote</a>
                  </button>
                }

                @if (agent.insuranceLinks[0]?.url; as insuranceLink) {
                  <button nz-button nzType="primary">
                    <a [href]="insuranceLink">Get Insurance Quote</a>
                  </button>
                }

                @if (agent.phones[0]; as phone) {
                  <button nz-button nzType="default">
                    <a [href]="'tel:' + phone">Call {{ phone }}</a>
                  </button>
                }
              </nz-card>
            }
          </div>

          @if (!last) {
            <nz-divider style="margin: 10px 0" />
          }
        </div>
      }
    }
  `,
  styles: [
    `
      .agent {
        min-height: 165px;
        position: relative;
        display: grid;
        justify-items: center;

        .photo {
          //transition: border $transition-out ease-in-out;
          width: 120px;
          height: 120px;
          border: solid 3px #cccccc;
          border-radius: 60px;
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
        }

        .name {
          transition: font-size 0.3s ease-in;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-top: 10px;
        }

        .icon {
          transition:
            fill 0.3s ease-in,
            width 0.3s ease-in,
            height 0.3s ease-in;
          position: absolute;
          top: 0;
          right: 0;
          fill: #00a0df;
          height: 20px;
          width: 20px;
        }

        &:focus,
        &:hover {
          .name {
            transition: font-size 0.3s ease-in;
            font-size: calc(18px * 1.1);
          }

          .icon {
            transition:
              fill 0.3s ease-in,
              width 0.3s ease-in,
              height 0.3s ease-in;
            fill: #00a0df;
            height: 30px;
            width: 30px;
          }
        }
      }
    `,
  ],
})
export class OfficeAgentsComponent implements OnInit, OnDestroy {
  afs = inject(AngularFirestore);
  officeAgentsService = inject(OfficeAgentsService);
  agentInterface = inject(AgentService);
  agentTypeInterface = inject(AgentTypeService);

  @Input() officeId: string | undefined;
  @Input() params: string | undefined;
  id: string | undefined;
  paramsObj: ParamsObj | undefined;
  officeAgentsSubscription: Subscription | undefined;
  officeAgents$: Observable<OfficeAgents> | undefined;

  private static getSortFunction(mode: AgentOrderMode): (a: OfficeAgent, b: OfficeAgent) => number {
    switch (mode) {
      default:
      case AgentOrderMode.RANDOM: //TODO - set some weight prop (randomly) earlier and sort by it here
      case AgentOrderMode.ALPHA:
        return (a: OfficeAgent, b: OfficeAgent) => {
          const lastNameComp = a.lastNames[0].localeCompare(b.lastNames[0]);
          if (lastNameComp !== 0) return lastNameComp;
          return a.firstNames[0].localeCompare(b.firstNames[0]);
        };
    }
  }

  ngOnInit(): void {
    this.id = this.afs.createId();

    if (this.params) {
      this.paramsObj = JSON.parse(this.params);
    }

    this.officeId = this.officeId || this.paramsObj?.options?.officeId;

    if (this.officeId) {
      this.officeAgents$ = combineLatest([this.agentInterface.agents$, this.agentTypeInterface.agentTypes$]).pipe(
        shareReplay(),
        map(([agents, agentTypes]) => {
          agents = (agents as OfficeAgent[]).reduce((accumulator: OfficeAgent[], agent: OfficeAgent) => {
            if (
              agent.pathName &&
              agent.status === '1' &&
              agent.offices.find((office) => office.nid === this.officeId)
            ) {
              agent.types.forEach((type) => {
                agent.agentTypeId = type.tid;
                accumulator = [agent, ...accumulator];
              });
            }
            return accumulator;
          }, []);
          return {
            agents: agents,
            agentTypes: agentTypes,
          };
        }),
      );

      this.officeAgentsSubscription = this.officeAgents$.subscribe((officeAgents) => {
        const paramsAgentOrderMode = this.paramsObj?.options?.agentOrderMode || 'RANDOM';
        const agentOrderMode: AgentOrderMode = AgentOrderMode[paramsAgentOrderMode as AgentOrderMode];

        if (this.id) {
          this.officeAgentsService.templateData[this.id] = officeAgents.agentTypes
            .map((agentType) => {
              return {
                agentType: agentType,
                agents: officeAgents.agents
                  .filter((agent) => agent.agentTypeId === agentType.id)
                  .sort(OfficeAgentsComponent.getSortFunction(agentOrderMode)),
              };
            })
            .sort((a, b) => {
              const weightDiff: number = parseInt(a.agentType.weight, 10) - parseInt(b.agentType.weight);

              if (weightDiff !== 0) {
                return weightDiff;
              } else {
                return a.agentType.officeHeading?.localeCompare(b.agentType.officeHeading);
              }
            })
            .filter((c) => c.agents?.length > 0) as AgentTypeOfficeAgents[];
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.officeAgentsSubscription?.unsubscribe();
  }
}
