@if (globalState$ | async; as g) {
  <div style="margin: 5px 0">
    <nz-upload
      [nzCustomRequest]="handleUpload"
      [nzListType]="listType"
      [(nzFileList)]="uploadFileList"
      [nzRemove]="handleRemove"
      [nzShowUploadList]="showUploadList"
      [nzShowButton]="showUploadButton"
      [nzPreview]="handlePreview"
    >
      @if (listType !== 'picture-card') {
        <button nz-button nzType="primary" (click)="$event.preventDefault()">
          <i nz-icon nzType="upload"></i>
          Upload
        </button>
      }

      @if (listType === 'picture-card') {
        <div>
          <i nz-icon nzType="plus"></i>
          <div style="margin-top: 8px">Upload</div>
        </div>
      }
    </nz-upload>
  </div>

  <div [style]="'margin: 5px 0'">
    <input
      placeholder="search image list"
      nz-input
      [(ngModel)]="inputValue"
      (ngModelChange)="onChange($event)"
      [nzAutocomplete]="auto"
    />
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>

  <div [style]="'margin: 5px 0'">
    <button
      nz-button
      [nzType]="showList ? 'default' : 'primary'"
      (click)="showList = !showList; $event.preventDefault()"
      style="margin-right: 10px"
    >
      Show Image List
    </button>
    <button
      nz-button
      [nzType]="showInstructions ? 'default' : 'primary'"
      (click)="showInstructions = !showInstructions; $event.preventDefault()"
      style="margin-right: 10px"
    >
      Show Instructions
    </button>
  </div>

  @if (showList) {
    <div style="margin: 5px 0">
      <nz-list nzBordered nzSize="small">
        @for (file of fileList; track file; let index = $index) {
          <nz-list-item (click)="handlePreview(file)">
            @if (g.adminUser) {
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <i
                    nz-icon
                    nzType="delete"
                    nzTheme="outline"
                    style="cursor: pointer"
                    (click)="fileDelete($event, file, index)"
                  ></i>
                </nz-list-item-action>
              </ul>
            }

            <div style="display: flex">
              <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
              <div style="padding: 0 8px">
                {{ file.name }}
              </div>
            </div>
          </nz-list-item>
        }
      </nz-list>
    </div>
  }

  <nz-modal
    [(nzVisible)]="showFilePreview"
    (nzOnCancel)="handleFilePreview()"
    (nzOnOk)="handleFilePreview()"
    [nzFooter]="null"
    nzWidth="90%"
  >
    <ava-file-manager-preview *nzModalContent [file]="fileManagerPreviewFile"></ava-file-manager-preview>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="showInstructions"
    (nzOnCancel)="handleInstructions()"
    (nzOnOk)="handleInstructions()"
    [nzFooter]="null"
    [nzBodyStyle]="{ padding: '30px' }"
  >
    <div *nzModalContent>
      <div>To add an image to this page you can either upload a new file or use an existing file.</div>
      <div style="margin: 10px">There are 3 ways to select an image.</div>

      <div style="font-weight: 900; margin: 10px">Upload a New Image</div>
      <ol>
        <li>Click on the [Upload] button and choose a file from your computer to upload.</li>
        <li>Your file will appear below the [Upload] button if the upload was successful.</li>
        <li>Select your file to open a preview popup</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>

      <div style="font-weight: 900; margin: 10px">Find an Existing Image with the Search</div>
      <ol>
        <li>Under the [Upload] button is a search field for existing files. Type the name of image.</li>
        <li>Choose an image from the search results to open a preview popup.</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>

      <div style="font-weight: 900; margin: 10px">Find an Existing Image from the Image List</div>
      <ol>
        <li>Under the search field is a button for Show Image List. Click the button.</li>
        <li>Choose an image from the Image List to open a preview popup.</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>
    </div>
  </nz-modal>

  <ng-template #instructions>
    <li>Copy the full url of the image starting with https://www.wssfiles.com/</li>
    <li>Paste the full url strand into the Image field below [Show Image List] button (and the Image List if open).</li>
  </ng-template>
}
