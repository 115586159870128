import { Component, Inject, Input, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { NzShowUploadList, NzUploadFile, NzUploadListType, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Subscription, Observable } from 'rxjs';
import { FileManagerService } from '../file-manager.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../services/state';
import { RxState } from '@rx-angular/state';

@Component({
  selector: 'ava-file-manager-upload',
  templateUrl: './file-manager-upload.component.html',
})
export class FileManagerUploadComponent implements OnInit {
  @Input() directory: string = '';
  @Input() fileList: NzUploadFile[] = [];

  globalState$ = this.globalState.select();

  uploadFileList: NzUploadFile[] = [];
  showList = false;
  showUploadList: boolean | NzShowUploadList = { showRemoveIcon: true, showDownloadIcon: false, showPreviewIcon: true };
  showUploadButton = true;
  ref: AngularFireStorageReference | undefined;
  task: AngularFireUploadTask | undefined;

  uploadProgress: { percent: number } = { percent: 0 };
  downloadURL = '';
  uploadState = false;

  listType: NzUploadListType = 'text';

  showInstructions = false;

  showFilePreview = false;
  fileManagerPreviewFile: NzUploadFile | null = null;

  inputValue?: string;
  options: string[] = [];
  filteredOptions: string[] = [];

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private afStorage: AngularFireStorage,
    private fmService: FileManagerService,
  ) {}

  ngOnInit(): void {
    this.fileList.forEach((file) => this.options.push(file.name));
  }

  handleUpload: (item: NzUploadXHRArgs) => Subscription = (item) => {
    this.task = this.afStorage.upload(this.directory + '/' + item.file.name, item.file, {
      cacheControl: 'public, max-age=31104000',
    });

    this.task.percentageChanges().subscribe((percent) => {
      if (percent !== undefined && typeof item.onProgress === 'function') {
        this.uploadProgress.percent = percent;
        item.onProgress(this.uploadProgress, item.file);
      }
    });

    return this.task.snapshotChanges().subscribe((snap) => {
      if (snap) {
        if (snap.state === 'success' && typeof item.onSuccess === 'function') {
          item.onSuccess('', item.file, '');
          this.uploadFileList[this.uploadFileList.length - 1].url =
            this.fmService.domain + '/' + this.directory + '/' + item.file.name;
        }
      }
    });
  };

  handleRemove: (file: NzUploadFile) => boolean | Observable<boolean> = (file) => {
    if (file.url) {
      this.afStorage.refFromURL(file.url).delete();
      return true;
    }
    return false;
  };

  fileDelete(event: Event, file: NzUploadFile, index: number): void {
    if (confirm('please confirm to delete') && file.url) {
      this.afStorage.refFromURL(file.url).delete();
      this.fileList.splice(index, 1);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  handlePreview: (file: NzUploadFile) => void = (file) => {
    // console.log(file)
    this.fileManagerPreviewFile = file;
    this.showFilePreview = true;
  };

  handleInstructions(): void {
    this.showInstructions = false;
  }

  handleFilePreview(): void {
    this.showFilePreview = false;
    this.fileManagerPreviewFile = null;
  }

  onChange(value: string): void {
    this.filteredOptions = this.options.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (this.fileList.find((file) => file.name === value)) {
      this.fileManagerPreviewFile = this.fileList.find((file) => file.name === value) || null;
      this.showFilePreview = true;
    }
  }
}
