import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';
import { WindowRefService } from '../modules/share/services/window-ref.service';

export interface AgentType {
  _type: 'agent_type';
  id: string;
  callout: string;
  description: string;
  insuranceProducts: string;
  name: string;
  officeHeading: string;
  quoteUrl: {
    url: string;
    title: string;
  };
  weight: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgentTypeService {
  private agentTypesRef: AngularFirestoreCollection<AgentType>;
  public agentTypes$: Observable<AgentType[]>;

  constructor(
    private afs: AngularFirestore,
    private window: WindowRefService,
  ) {
    this.agentTypesRef = this.afs
      .collection('wss-aaa-web')
      .doc(this.window.nativeWindow.metaData.clubId)
      .collection('data')
      .doc('drupal')
      .collection('terms', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('_type', '==', 'agentType');
        return query;
      });

    this.agentTypes$ = this.agentTypesRef.snapshotChanges().pipe(
      map((agentTypes: DocumentChangeAction<AgentType>[]) => {
        return agentTypes.map((result) => {
          if (result) {
            const data = result.payload.doc.data();
            data.id = result.payload.doc.id;
            return data;
          }
          return result;
        });
      }),
    );
  }
}
