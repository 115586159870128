import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';
import { WindowRefService } from '../modules/share/services/window-ref.service';

export interface Agent {
  _type: 'agent';
  id: string;
  title: string;
  pathName: string;
  status: '0' | '1';
  firstNames: string[];
  lastNames: string[];
  phones: string[];
  photos: {
    fid: string;
    original: string;
    alt: string;
    title: string;
    derivatives: {
      searchResult: string;
    };
  }[];
  services: {
    tid: string;
  }[];
  types: {
    tid: string;
  }[];
  offices: {
    nid: string;
  }[];
  travelLinks: {
    label: string;
    url: string;
  }[];
  insuranceLinks: {
    label: string;
    url: string;
  }[];
  appointmentLinks: {
    label: string;
    url: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private agentsRef: AngularFirestoreCollection<Agent>;
  public agents$: Observable<Agent[]>;

  constructor(
    private afs: AngularFirestore,
    private window: WindowRefService,
  ) {
    this.agentsRef = this.afs
      .collection('wss-aaa-web')
      .doc(this.window.nativeWindow.metaData.clubId)
      .collection('data')
      .doc('drupal')
      .collection('nodes', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('_type', '==', 'agent');
        return query;
      });
    this.agents$ = this.agentsRef.snapshotChanges().pipe(
      map((agents: DocumentChangeAction<Agent>[]) => {
        return agents.map((result) => {
          if (result) {
            const data = result.payload.doc.data();
            data.id = result.payload.doc.id;
            return data;
          }
          return result;
        });
      }),
    );
  }
}
